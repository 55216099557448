import React, { useEffect, useState } from "react";
import Header from "../components/header.js";
import LogoutButton from "../components/logoutButton.js";
import style from "../styles/assetSelection.module.css";

import json_data from "../data/data.json";
import CustomButton from "../components/customButton.js";
import CustomOutlineButton from "../components/customOutlineButton.js";
import { ERROR_MEDIUM, TEXT_HEADING } from "../constants/colors.js";
import ic_refresh from "../icons/ic_refresh.png";
import ic_right from "../icons/ic_right.png";
import { useNavigate } from "react-router-dom";
import HorizontalDivider from "../components/horizontalDivider.js";
import GenerateFormFromTemplate from "../components/generateFormFromTemplate.js";
import { BackendAPI } from "../api";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDetails,
  continuePreviousDetails,
  updateDetails,
  updatePreviousData,
} from "../features/inspection/inspectionSlice.js";
import Loader from "../components/loader.js";
import LoaderModal from "../modals/loaderModal.js";
import ToastMessage from "../components/toastMessage.js";
import { addAppData, resetAppData } from "../features/appData/appDataSlice.js";
import { useCookies } from "react-cookie";
import { auth } from "../firebase/config.js";
import SingleOptionModal from "../modals/singleOptionModal.js";
import DynamicDropdown from "../components/dynamicDropdown.js";
import { logoutUser } from "../features/user/userSlice.js";
import { sortArrayOfObjects, handleValidations } from "../functions/index.js";

const AssetSelection = () => {
  const [state, setState] = useState({});
  const [errorTracker, setErrorTracker] = useState({});
  const [showPageLoadingModal, setShowPageLoadingModal] = useState(false);
  const [pageError, setPageError] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);
  const inProgressInspection = useSelector((st) => st.inspection.previousData);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("Success");
  const [toastType, setToastType] = useState("success"); //success, error, warning, info
  const [cookie, setCookie, deleteCookie] = useCookies(["electrifi-mobility"]);
  const [gotUserAddress, setGotUserAddress] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(json_data);
  const inspectionData = useSelector((st) => st.inspection);
  const userData = useSelector((st) => st.user);

  useEffect(() => {
    dispatch(clearDetails());
    setShowPageLoadingModal(true);
    var listener;
    if (cookie && cookie["signin-status"]) {
      listener = auth.onAuthStateChanged((stat) => {
        if (stat) {
          getPreviousInspectionDetails();
          getUserLocation();
        }
      });
      setTimeout(() => {
        if (!auth.currentUser) {
          logout();
        } else {
          setCookie("uid", auth.currentUser.uid, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          setCookie("signin-status", true, { path: "/", maxAge: 60 * 60 * 24 });
        }
      }, 5000);
    } else {
      logout();
    }
    return () => {
      if (listener) listener();
    };
  }, []);

  const getPreviousInspectionDetails = async () => {
    try {
      var token = await auth.currentUser.getIdToken();
      var configs = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };
      var resp = await BackendAPI.get(
        `/inspection/previous_inspection`,
        configs
      );
      if (resp.status === 200) {
        dispatch(updatePreviousData(resp.data.data));
      } else if (resp.status !== 500) {
        setToastMsg(resp.data.message);
        setToastType("warning");
        setShowToast(true);
        setShowPageLoadingModal(false);
      } else {
        setToastMsg("Facing issues while getting Previous Inspection Details");
        setToastType("warning");
        setShowToast(true);
        setShowPageLoadingModal(false);
      }
      setShowPageLoadingModal(false);
    } catch (error) {
      console.log(error);
      setToastMsg("Facing issues while loading previous inspection details");
      setToastType("warning");
      setShowToast(true);
      setShowPageLoadingModal(false);
    }
  };

  const getUserLocation = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (val) => {
          var token = await auth.currentUser.getIdToken();
          var configs = {
            headers: {
              Authorization: `bearer ${token}`,
            },
          };
          var lat = val.coords.latitude,
            lng = val.coords.longitude;
          var resp = await BackendAPI.get(
            `/external/user-location?lat=${lat}&lng=${lng}`,
            configs
          );
          if (resp.status === 200) {
            if (resp.data?.data?.status === "OK") {
              var descriptor = sortArrayOfObjects(
                resp.data.data.address_descriptor.landmarks,
                "straight_line_distance_meters"
              );
              var nearLandmark =
                descriptor && descriptor.length > 0
                  ? descriptor[0].display_name.text
                  : null;
              var add =
                (nearLandmark ? nearLandmark + ", " : "") +
                resp.data.data.results[0]?.formatted_address;
              updateUserAddress(add, lat, lng);
            } else {
              updateUserAddress("", lat, lng);
              setToastMsg("Unable to get the address");
              setToastType("warning");
              setShowToast(true);
              setShowPageLoadingModal(false);
            }
          } else if (resp.status !== 500) {
            updateUserAddress("", lat, lng);
            setToastMsg("Unable to get the address");
            setToastType("warning");
            setShowToast(true);
            setShowPageLoadingModal(false);
          } else {
            updateUserAddress("", lat, lng);
            setToastMsg("Unable to get the address");
            setToastType("warning");
            setShowToast(true);
            setShowPageLoadingModal(false);
          }
          setShowPageLoadingModal(false);
        });
      }
    } catch (error) {
      console.log(error);
      setToastMsg("Unable to get the address");
      setToastType("warning");
      setShowToast(true);
      setShowPageLoadingModal(false);
    }
  };

  useEffect(() => {
    if (gotUserAddress) {
      changeStateValues(gotUserAddress, gotUserAddress, "asset_address");
    }
  }, [gotUserAddress]);

  const updateUserAddress = (add, lat, lng) => {
    var data = {
      address: add,
      loc: {
        lat: lat,
        lng: lng,
      },
    };
    setGotUserAddress(data);
  };

  const getLeftIcon = () => {
    return (
      <div className={style.button_left_fixed_icon}>
        <img className={style.button_icon} src={ic_refresh} />
      </div>
    );
  };

  const getRightIcon = () => {
    return (
      <div className={style.button_right_fixed_icon}>
        <img className={style.button_icon} src={ic_right} />
      </div>
    );
  };

  const changeStateValues = (obj, val, label) => {
    if (typeof val === "string") {
      val = val.trim();
    }
    var newState = { ...state, [label]: { data: val, include: true } };
    setState(newState);
    if (label === "asset_ownership" && val === "external") {
      var newData = [];
      for (var val of data.sections.selectAsset) {
        if (val.code === "inspection_type") {
          val.options = {
            inspection: {
              value: "inspection",
              name: "Inspection",
            },
          };
          newData.push(val);
        } else {
          newData.push(val);
        }
      }
      var newDataState = data;
      newDataState.sections.selectAsset = newData;
      setData(newDataState);
      setState({ ...newState, inspection_type: "" });
    } else if (label === "asset_ownership") {
      var newData = [];
      for (var val of data.sections.selectAsset) {
        if (val.code === "inspection_type") {
          val.options = {
            inspection: {
              value: "inspection",
              name: "Inspection",
            },
            pdi: {
              value: "PDI",
              name: "Pre-Delivery Check",
            },
          };
          newData.push(val);
        } else {
          newData.push(val);
        }
      }
      var newDataState = data;
      newDataState.sections.selectAsset = newData;
      setData(newDataState);
    }
  };

  const validateTheData = (newState) => {
    var dt = data.sections.selectAsset;
    dt = [
      ...dt,
      {
        code: "asset_owner_id",
        name: "Asset Owner",
        validation: {
          required: true,
          dependency: {
            asset_ownership: {
              value: "electrifi",
              required: false,
            },
          },
        },
      },
    ];
    var newError = {};
    var validationSuccess = true;

    for (var i of dt) {
      var validation;
      if (i.code === "asset_address") {
        validation = handleValidations(
          newState[i.code]?.address,
          i.validation,
          newState,
          inspectionData
        );
      } else {
        validation = handleValidations(
          newState[i.code],
          i.validation,
          newState,
          inspectionData
        );
      }

      if (!validation.success) {
        validationSuccess = false;
        newError[i.code] = validation.message;
      }
    }
    setErrorTracker(newError);
    return validationSuccess;
  };

  const createAndRedirect = async () => {
    setPageLoading(true);
    var newState = {};
    for (const [key, value] of Object.entries(state)) {
      newState[key] = value?.data;
    }
    if (newState["asset_ownership"] === "electrifi") {
      newState["asset_owner_id"] = "electrifi";
    }
    var validation = validateTheData(newState);
    if (!validation) {
      setPageLoading(false);
      return;
    }

    var postData = {
      ...newState,
      templateVersion: data.version,
      assetSerial: "",
    };

    if (
      !["2w", "3w", "bat"].includes(postData.asset_type) &&
      !(postData.asset_type === "4w" && postData.inspection_type === "PDI")
    ) {
      setToastMsg(
        `${postData.asset_type} inspection feature is not available yet!`
      );
      setToastType("error");
      setShowToast(true);
      setPageLoading(false);
      return;
    }
    setPageLoading(false);
    dispatch(updateDetails(postData));
    dispatch(addAppData({ inspection_type: "new" }));
    navigation("/search-asset");
  };

  const continuePreviousInspection = async () => {
    setContinueButtonLoading(true);
    const inspectionId = inProgressInspection.inspection_id;
    var token = await auth.currentUser.getIdToken();
    var configs = {
      headers: {
        Authorization: `bearer ${token}`,
      },
    };
    try {
      var resp = await BackendAPI.get(
        `/inspection/asset/details?inspection_id=${inspectionId}`,
        configs
      );
      if (resp?.data?.data) {
        dispatch(
          updateDetails({
            electrifi_data: resp.data.data,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(continuePreviousDetails(inProgressInspection));
    dispatch(addAppData({ inspection_type: "continue" }));
    navigation("/asset/inspection");
  };

  const logout = () => {
    deleteCookie("uid");
    deleteCookie("signin-status");
    deleteCookie("name");
    dispatch(clearDetails());
    dispatch(resetAppData());
    dispatch(logoutUser());
    navigation("/login");
  };

  return (
    <div className={style.container}>
      <Header
        headerDescription={
          cookie && cookie["name"]
            ? cookie["name"]
            : userData.name
            ? userData.name
            : ""
        }
        endItem={<LogoutButton />}
        marginHorizontal="4%"
        marginTop="4%"
        width="92%"
      />
      <LoaderModal show={showPageLoadingModal} />
      <SingleOptionModal
        show={showLogoutModal}
        title="You have been logged out. Please login again"
        buttonText="Login"
        onClick={logout}
      />
      <ToastMessage
        message={toastMsg}
        type={toastType}
        open={showToast}
        setOpen={(v) => setShowToast(v)}
      />
      <HorizontalDivider />
      {data.sections?.selectAsset ? (
        <GenerateFormFromTemplate
          data={data.sections.selectAsset}
          parent="selectAsset"
          state={state}
          onChange={changeStateValues}
          errors={errorTracker}
          onLocationClick={getUserLocation}
        />
      ) : (
        <div />
      )}
      {state.asset_ownership?.data === "external" ? (
        <div style={{ width: "90%", margin: "3% 5% 0 5%" }}>
          <DynamicDropdown
            label="Whose Asset is it?"
            placeholder="Asset Owner"
            value={state["asset_owner_id"]}
            onChange={(e) => {
              changeStateValues(e, e.value, "asset_owner_id");
            }}
            onTextChange={(e) =>
              changeStateValues(e, e.target.value, "asset_owner_id")
            }
            url={`/assetmanagement/asset-owners?asset_class_type=${
              state.asset_type ? state.asset_type.data : "2w"
            }`}
            url_mapper={{
              name: "name",
              code: "value",
            }}
            freeSolo
            required={true}
            errored={errorTracker["asset_owner_id"] ? true : false}
            errorMessage={errorTracker["asset_owner_id"]}
          />
        </div>
      ) : null}
      {pageError ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p style={{ fontSize: "small", color: ERROR_MEDIUM }}>{pageError}</p>
        </div>
      ) : null}
      <div className={style.continue_button_container}>
        <div className={style.continue_button}>
          <CustomButton
            buttonText={pageLoading ? <Loader /> : "Continue"}
            onClick={() => {
              if (
                !pageLoading &&
                !continueButtonLoading &&
                !showPageLoadingModal
              ) {
                createAndRedirect();
              }
            }}
          />
        </div>
      </div>
      <div className={style.continue_inspection_button_container}>
        {inProgressInspection?.inspection_id ? (
          <div className={style.continue_inspection_button}>
            <CustomOutlineButton
              buttonText={
                continueButtonLoading ? (
                  <Loader />
                ) : (
                  "Continue Ongoing Inspection (ID " +
                  inProgressInspection.inspection_id +
                  ")"
                )
              }
              variant="outlined"
              color="transparent"
              tappedColor="#F7F8F9"
              borderColor="#00357C"
              tappedBorderColor="#00357C"
              textColor={TEXT_HEADING}
              fontSize="12px"
              startIcon={getLeftIcon()}
              endIcon={getRightIcon()}
              onClick={continuePreviousInspection}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AssetSelection;
