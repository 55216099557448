import React, { useEffect, useState } from "react";
import TopBanner from "../components/topBanner";
import BottomBanner from "../components/bottomBanner";
import HorizontalDivider from "../components/horizontalDivider";
import GenerateFormFromTemplate from "../components/generateFormFromTemplate";
import Loader from "../components/loader";
import DamageExtentMenu from "../components/damageExtentMenu";
import style from "../styles/assetInspection.module.css";
import ic_success from "../icons/ic_success.png";
import ic_right from "../icons/ic_right.png";
import ic_error from "../icons/ic_error.png";
import CustomOutlineButton from "../components/customOutlineButton";
import CustomButton from "../components/customButton";
import {
  ERROR_DARK,
  PRIMARY_1_SHADE_1,
  SUCCESS_MEDIUM,
} from "../constants/colors";
import { useNavigate } from "react-router-dom";
import { BackendAPI } from "../api";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDetails,
  updateFormDetails,
  updateStatuses,
} from "../features/inspection/inspectionSlice";
import ToastMessage from "../components/toastMessage";
import OptionsModal from "../modals/optionsModal";
import { useCookies } from "react-cookie";
import { auth } from "../firebase/config";
import SingleOptionModal from "../modals/singleOptionModal";
import Compressor from "compressorjs";
import handleValidations from "../functions/handleValidations";
import LoaderModal from "../modals/loaderModal";
import { logoutUser } from "../features/user/userSlice";
import { rcNumberValidator } from "../functions/validationFunctions";
import { resetAppData } from "../features/appData/appDataSlice";

const AssetInspection = () => {
  const [curIndex, setCurIndex] = useState();
  const [maxIndex, setMaxIndex] = useState(0);
  const [data, setData] = useState();
  const [currentPageData, setCurrentPageData] = useState();
  const [state, setState] = useState();
  const [cameraFieldDisplay, setCameraFieldDisplay] = useState("none");
  const [showDamageMenu, setShowDamageMenu] = useState(false);
  const [damageYCoordinates, setDamageYCoordinates] = useState(0);
  const [damagePointerCoordinates, setDamagePointerCoordinates] = useState();
  const statuses = useSelector((st) => st.inspection.statuses);
  const [damagePosition, setDamagePosition] = useState("bottom");
  const [damageOptions, setDamageOptions] = useState({});
  const [tempValues, setTempValues] = useState();
  const [formCompletedOnce, setFormCompletedOnce] = useState(false);
  const [showStatusPage, setShowStatusPage] = useState(false);
  const inspectionData = useSelector((st) => st.inspection);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("Success");
  const [toastType, setToastType] = useState("success"); //success, error, warning, info
  const [pageError, setPageError] = useState();
  const [showPageLoadingModal, setShowPageLoadingModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const inspectionType = useSelector((st) => st.appData.inspection_type);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [optionsMenuDetails, setOptionsMenuDetails] = useState({});
  const [cookie, setCookie, deleteCookie] = useCookies(["electrifi-mobility"]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [errorTracker, setErrorTracker] = useState({});
  const [supportingPhotos, setSupportingPhotos] = useState({});
  const [requiredSupportingPhotos, setRequiredSupportingPhotos] = useState({});
  const [requiredFields, setRequiredFields] = useState({});
  const [hiddenFields, setHiddenFields] = useState({});
  const [dependencies, setDependencies] = useState({});
  const [validationObj, setValidationObj] = useState({});

  const navigation = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setShowPageLoadingModal(true);
    if (!inspectionData || !inspectionData.inspection_id) {
      navigation("/select-asset", { replace: true });
    }
    var listener = auth.onAuthStateChanged((stat) => {
      if (stat) {
        getTemplateData();
      }
    });
    if (cookie && cookie["signin-status"]) {
      if (inspectionType === "continue") {
        var newState = { ...inspectionData.sections };
        if (
          inspectionData.electrifi_data &&
          inspectionData.asset_type === "bat"
        ) {
          newState["basic_details"] = {
            ...newState["basic_details"],
            serial_number: {
              data: inspectionData.electrifi_data?.chassisNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.chassisNumber
                ? true
                : false,
              ...newState["basic_details"].serial_number,
            },
            make: {
              data: inspectionData.electrifi_data?.make,
              include: true,
              disabled: inspectionData.electrifi_data?.make ? true : false,
              ...newState["basic_details"]?.make,
            },
          };
        } else if (
          // inspectionData.asset_ownership === "electrifi" &&
          inspectionData.electrifi_data
        ) {
          newState["basic_details"] = {
            ...newState["basic_details"],
            chassis_no: {
              data: inspectionData.electrifi_data?.chassisNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.chassisNumber
                ? true
                : false,
              ...newState["basic_details"].chassis_no,
            },
            rc_number: {
              data: inspectionData.electrifi_data?.rcNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.rcNumber ? true : false,
              ...newState["basic_details"]?.rc_number,
            },
            vehicle_speed_type: {
              data: inspectionData.electrifi_data?.evType,
              include: true,
              disabled: inspectionData.electrifi_data?.evType ? true : false,
              ...newState["basic_details"]?.vehicle_speed_type,
            },
            make: {
              data: inspectionData.electrifi_data?.make,
              include: true,
              disabled: inspectionData.electrifi_data?.make ? true : false,
              ...newState["basic_details"]?.make,
            },
            model: {
              data: inspectionData.electrifi_data?.model,
              include: true,
              disabled: inspectionData.electrifi_data?.model ? true : false,
              ...newState["basic_details"]?.model,
            },
          };
        }
        setState(newState);
        setSupportingPhotos(inspectionData.supporting_photos);
        if (!showStatusPage) setShowStatusPage(true);
      }
      setTimeout(() => {
        if (!auth.currentUser) {
          setShowPageLoadingModal(false);
          setShowLogoutModal(true);
        } else {
          setCookie("uid", auth.currentUser.uid, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          setCookie("signin-status", true, { path: "/", maxAge: 60 * 60 * 24 });
        }
      }, 5000);
    } else {
      if (inspectionType === "continue") {
        var newState = { ...inspectionData.sections };
        if (
          inspectionData.electrifi_data &&
          inspectionData.asset_type === "bat"
        ) {
          newState["basic_details"] = {
            ...newState["basic_details"],
            serial_number: {
              data: inspectionData.electrifi_data?.chassisNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.chassisNumber
                ? true
                : false,
              ...newState["basic_details"].serial_number,
            },
            make: {
              data: inspectionData.electrifi_data?.make,
              include: true,
              disabled: inspectionData.electrifi_data?.make ? true : false,
              ...newState["basic_details"]?.make,
            },
          };
        } else if (
          // inspectionData.asset_ownership === "electrifi" &&
          inspectionData.electrifi_data
        ) {
          newState["basic_details"] = {
            ...newState["basic_details"],
            chassis_no: {
              data: inspectionData.electrifi_data?.chassisNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.chassisNumber
                ? true
                : false,
            },
            rc_number: {
              data: inspectionData.electrifi_data?.rcNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.rcNumber ? true : false,
            },
            vehicle_speed_type: {
              data: inspectionData.electrifi_data?.evType,
              include: true,
              disabled: inspectionData.electrifi_data?.evType ? true : false,
            },
            make: {
              data: inspectionData.electrifi_data?.make,
              include: true,
              disabled: inspectionData.electrifi_data?.make ? true : false,
            },
            model: {
              data: inspectionData.electrifi_data?.model,
              include: true,
              disabled: inspectionData.electrifi_data?.model ? true : false,
            },
          };
        }
        setState(newState);
        setSupportingPhotos(inspectionData.supporting_photos);
        if (!showStatusPage) setShowStatusPage(true);
      }
      setTimeout(() => {
        if (auth.currentUser) {
          setCookie("uid", auth.currentUser.uid, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          setCookie("signin-status", true, { path: "/", maxAge: 60 * 60 * 24 });
          if (inspectionType === "continue") {
            var newState = { ...inspectionData.sections };
            if (
              inspectionData.electrifi_data &&
              inspectionData.asset_type === "bat"
            ) {
              newState["basic_details"] = {
                ...newState["basic_details"],
                serial_number: {
                  data: inspectionData.electrifi_data?.chassisNumber,
                  include: true,
                  disabled: inspectionData.electrifi_data?.chassisNumber
                    ? true
                    : false,
                  ...newState["basic_details"].serial_number,
                },
                make: {
                  data: inspectionData.electrifi_data?.make,
                  include: true,
                  disabled: inspectionData.electrifi_data?.make ? true : false,
                  ...newState["basic_details"]?.make,
                },
              };
            } else if (
              // inspectionData.asset_ownership === "electrifi" &&
              inspectionData.electrifi_data
            ) {
              newState["basic_details"] = {
                ...newState["basic_details"],
                chassis_no: {
                  data: inspectionData.electrifi_data?.chassisNumber,
                  include: true,
                  disabled: inspectionData.electrifi_data?.chassisNumber
                    ? true
                    : false,
                },
                rc_number: {
                  data: inspectionData.electrifi_data?.rcNumber,
                  include: true,
                  disabled: inspectionData.electrifi_data?.rcNumber
                    ? true
                    : false,
                },
                vehicle_speed_type: {
                  data: inspectionData.electrifi_data?.evType,
                  include: true,
                  disabled: inspectionData.electrifi_data?.evType
                    ? true
                    : false,
                },
                make: {
                  data: inspectionData.electrifi_data?.make,
                  include: true,
                  disabled: inspectionData.electrifi_data?.make ? true : false,
                },
                model: {
                  data: inspectionData.electrifi_data?.model,
                  include: true,
                  disabled: inspectionData.electrifi_data?.model ? true : false,
                },
              };
            }
            setState(newState);
            setSupportingPhotos(inspectionData.supporting_photos);
            if (!showStatusPage) setShowStatusPage(true);
          }
        } else {
          setShowPageLoadingModal(false);
          setShowLogoutModal(true);
        }
      }, 5000);
    }
    return () => {
      listener();
    };
  }, []);

  const getDependObj = (arr, obj, addObj) => {
    if (arr.length <= 0) {
      return obj;
    }
    var cur_ind = arr[0];
    arr = arr.slice(1);
    if (arr.length > 0) {
      if (obj[cur_ind]) {
        var nextObj = getDependObj(arr, obj[cur_ind], addObj);
        obj[cur_ind] = nextObj;
      } else {
        var nextObj = getDependObj(arr, {}, addObj);
        obj[cur_ind] = nextObj;
      }
      return obj;
    } else {
      if (obj[cur_ind]) {
        obj[cur_ind][addObj] = true;
        return obj;
      } else {
        obj[cur_ind] = {};
        obj[cur_ind][addObj] = true;
        return obj;
      }
    }
  };

  useEffect(() => {
    if (data) {
      var sections = data.sections;
      var hidden = {};
      var required = {};
      var depend = {};
      var validation = {};
      for (var i of Object.keys(sections)) {
        var cur_section = sections[i];
        hidden[i] = {};
        required[i] = {};
        validation[i] = {};
        for (var j of cur_section) {
          if (j && j.validation) {
            validation[i][j.code] = j.validation;
            hidden[i][j.code] = j.validation.hidden ? true : false;
            required[i][j.code] = j.validation.required ? true : false;
            if (j.validation.dependency) {
              for (var k of Object.keys(j.validation.dependency)) {
                var dif_keys = k.split(".");
                if (dif_keys.length <= 0) {
                  continue;
                }
                var get_depend_obj = getDependObj(
                  dif_keys,
                  depend,
                  i + "." + j.code
                );
                depend = get_depend_obj;
              }
            }
          }
        }
      }
      if (state || inspectionData.electrifi_data) {
        var newState = {};
        for (var i of Object.keys(sections)) {
          newState[i] = {};
          if (state && state[i]) {
            for (var j of Object.keys(state[i])) {
              newState[i][j] = state[i][j].data;
            }
          }
        }
        if (
          inspectionData.electrifi_data &&
          inspectionData.asset_type === "bat"
        ) {
          newState["basic_details"] = {
            ...newState["basic_details"],
            serial_number: {
              data: inspectionData.electrifi_data?.chassisNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.chassisNumber
                ? true
                : false,
              ...newState["basic_details"].serial_number,
            },
            make: {
              data: inspectionData.electrifi_data?.make,
              include: true,
              disabled: inspectionData.electrifi_data?.make ? true : false,
              ...newState["basic_details"]?.make,
            },
          };
        } else if (
          // inspectionData.asset_ownership === "electrifi" &&
          inspectionData.electrifi_data
        ) {
          newState["basic_details"] = {
            chassis_no: inspectionData.electrifi_data?.chassisNumber,
            rc_number: inspectionData.electrifi_data?.rcNumber,
            vehicle_speed_type: inspectionData.electrifi_data?.evType,
            make: inspectionData.electrifi_data?.make,
            model: inspectionData.electrifi_data?.model,
          };
        }
        for (var i of Object.keys(sections)) {
          for (var j of sections[i]) {
            var valid = handleValidations(
              newState[i]?.[j.code],
              validation[i][j.code],
              newState,
              inspectionData
            );
            hidden[i][j.code] = valid.hidden ? valid.hidden : false;
            required[i][j.code] = valid.required ? valid.required : false;
          }
        }
      }
      setValidationObj(validation);
      setDependencies(depend);
      setHiddenFields(hidden);
      setRequiredFields(required);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      var sections = data.sections;
      var sequences = data.sequence;
      if (curIndex >= 0) {
        var cur_page = sequences[curIndex];
      }
      if (!state) {
        var cur_page = sequences[0];
        var newState = {};
        newState["section_identifier"] = (
          inspectionData.asset_type +
          "_" +
          inspectionData.inspection_type
        ).toLowerCase();
        var newError = {};
        for (var i of sequences) {
          newState[i.code] = {};
          newError[i.code] = {};
          dispatch(
            updateStatuses({
              code: i.code,
              status: {
                status: false,
                message: "The form has not been completed",
              },
            })
          );
        }
        if (
          inspectionData.electrifi_data &&
          inspectionData.asset_type === "bat"
        ) {
          newState["basic_details"] = {
            ...newState["basic_details"],
            serial_number: {
              data: inspectionData.electrifi_data?.chassisNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.chassisNumber
                ? true
                : false,
              ...newState["basic_details"].serial_number,
            },
            make: {
              data: inspectionData.electrifi_data?.make,
              include: true,
              disabled: inspectionData.electrifi_data?.make ? true : false,
              ...newState["basic_details"]?.make,
            },
          };
        } else if (
          // inspectionData.asset_ownership === "electrifi" &&
          inspectionData.electrifi_data
        ) {
          newState["basic_details"] = {
            chassis_no: {
              data: inspectionData.electrifi_data?.chassisNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.chassisNumber
                ? true
                : false,
            },
            rc_number: {
              data: inspectionData.electrifi_data?.rcNumber,
              include: true,
              disabled: inspectionData.electrifi_data?.rcNumber ? true : false,
            },
            vehicle_speed_type: {
              data: inspectionData.electrifi_data?.evType,
              include: true,
              disabled: inspectionData.electrifi_data?.evType ? true : false,
            },
            make: {
              data: inspectionData.electrifi_data?.make,
              include: true,
              disabled: inspectionData.electrifi_data?.make ? true : false,
            },
            model: {
              data: inspectionData.electrifi_data?.model,
              include: true,
              disabled: inspectionData.electrifi_data?.model ? true : false,
            },
          };
        }
        setState(newState);
        setErrorTracker(newError);
        if (!curIndex) {
          setCurIndex(0);
        }
      }
      if (cur_page) {
        var cur_page_data = sections[String(cur_page.code).toLowerCase()];
        setCurrentPageData(cur_page_data);
      }
    }
  }, [curIndex, data]);

  const getTemplateData = async () => {
    if (
      auth &&
      auth.currentUser &&
      inspectionData &&
      inspectionData.asset_type &&
      inspectionData.inspection_type
    ) {
      var token = await auth.currentUser.getIdToken();
      var configs = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };
      try {
        var resp = await BackendAPI.get(
          `/template/${inspectionData.asset_type}/${inspectionData.inspection_type}`,
          configs
        );
        var data = await resp.data;
        setShowPageLoadingModal(false);
        if (resp.status === 200) {
          if (data && data.data) {
            setData(data.data);
            if (data.data.sequence) {
              setMaxIndex(data.data.sequence.length - 1);
            }
          }
        } else {
          var msg;
          if (resp.data && resp.data.data) {
            if (resp.data.data[0].msg) msg = resp.data.data[0].msg;
            if (resp.data.data[0].message) msg = resp.data.data[0].message;
          }
          setShowPageLoadingModal(false);
          if (!msg) {
            msg = "Unable to load the template";
          }
          setToastMsg(msg);
          setToastType("error");
          setShowToast(true);
        }
      } catch (error) {
        setShowPageLoadingModal(false);
        setToastMsg("Unable to load the template");
        setToastType("error");
        setShowToast(true);
        console.log(error);
      }
    }
  };

  const updateState = (
    obj,
    val,
    code,
    fieldType = "",
    setLoadingFunction = null
  ) => {
    if (fieldType === "image") {
      uploadInspectionPhoto(obj, val, code, setLoadingFunction);
      return;
    }
    var parent = data.sequence[curIndex];
    var newObj = { ...state[parent.code] };
    if (code === "rc_number" && parent.code === "basic_details") {
      val = val.replace(/[\s]+/, "");
    } else {
      val = val.replace(/[\s]+/, " ");
    }
    if (fieldType === "multiselect") {
      if (newObj[code]) {
        var dt = newObj[code]["data"];
        var new_dt;
        if (dt) {
          if (!dt.includes(val)) {
            new_dt = [...dt, val];
          } else {
            var ind = dt.indexOf(val);
            dt.splice(ind, 1);
            new_dt = dt;
          }
        } else {
          new_dt = [val];
        }
        newObj[code] = { data: new_dt, include: true };
      } else {
        newObj[code] = { data: [val], include: true };
      }
    } else {
      newObj[code] = { data: val, include: true };
    }
    if (parent.code === "basic_details" && code === "make") {
      newObj["model"] = "";
    }
    var requiredPhotos = { ...requiredSupportingPhotos };
    if (requiredPhotos[parent.code] && requiredPhotos[parent.code][code]) {
      requiredPhotos[parent.code][code] = false;
    }
    setRequiredSupportingPhotos(requiredPhotos);
    var upd_state = { ...state, [parent.code]: newObj };
    var depends = dependencies[parent.code];
    depends = depends?.[code];
    var hidden = hiddenFields;
    var required = requiredFields;
    var newState = {};
    var dt = data.sequence;
    var updState = { ...upd_state };
    for (var i of dt) {
      if (upd_state[i.code]) {
        newState[i.code] = {};
        updState[i.code] = {};
        for (const [key, value] of Object.entries(upd_state[i.code])) {
          newState[i.code][key] = value?.data;
          updState[i.code][key] = value;
        }
      } else {
        newState[i.code] = {};
        updState[i.code] = {};
      }
    }
    if (depends) {
      for (var i of Object.keys(depends)) {
        var splitted = i.split(".");
        var value = state;
        for (var j of splitted) {
          value = value[j];
        }
        var init_null = false;
        if (value) {
          if (typeof value === "object") {
            value = value.data;
          } else {
            init_null = true;
          }
        } else {
          init_null = true;
        }
        var valid = handleValidations(
          value,
          validationObj[splitted[0]][splitted[1]],
          newState,
          inspectionData
        );
        hidden[splitted[0]][splitted[1]] = valid.hidden ? valid.hidden : false;
        required[splitted[0]][splitted[1]] = valid.required
          ? valid.required
          : false;
        if (valid.hidden) {
          if (!updState[splitted[0]]) updState[splitted[0]] = {};
          updState[splitted[0]][splitted[1]] = {
            ...updState[splitted[0]][splitted[1]],
            data: null,
          };
          if (updState[splitted[0]][splitted[1]].disabled) {
            updState[splitted[0]][splitted[1]].disabled = false;
          }
        }
      }
      setHiddenFields(hidden);
      setRequiredFields(required);
      upd_state = updState;
    }
    setState(upd_state);
    if (errorTracker[code]) {
      var validator = validationObj[parent.code][code];
      var validation = handleValidations(
        val,
        validator,
        newState,
        inspectionData
      );
      var thisError = null;
      hidden[parent.code][code] = validation.hidden;
      required[parent.code][code] = validation.required;
      if (
        validation.success &&
        code === "rc_number" &&
        parent.code === "basic_details"
      ) {
        var validation2 = rcNumberValidator(val);
        if (!validation2) {
          validation = {
            success: false,
            message: "Please provide a valid RC Number",
          };
        }
      }

      if (!validation.success) {
        thisError = validation.message;
      }
      setErrorTracker({ ...errorTracker, [code]: thisError });
    }
  };

  const uploadInspectionPhoto = (obj, val, code, setLoadingFunction) => {
    if (val) {
      new Compressor(val, {
        quality: 0.4,
        convertSize: 100000,
        success: async (result) => {
          var token = await auth.currentUser.getIdToken();
          var configs = {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          };
          var formData = new FormData();
          var parent = data.sequence[curIndex];
          formData.append("image", result, result.name);
          var resp = await BackendAPI.post(
            `/inspection/upload/image?key=${code}&inspection_id=${inspectionData.inspection_id}&section=${parent.code}`,
            formData,
            configs
          );
          if (resp.status === 200) {
            var newObj = { ...state[parent.code] };
            var newErr = { ...errorTracker };
            newErr[code] = null;
            newObj[code] = { data: resp.data.data.url, include: false };
            setState({ ...state, [parent.code]: newObj });
            setErrorTracker(newErr);
            setLoadingFunction(false);
          } else {
            var newErr = { ...errorTracker };
            if (resp.data && resp.data.data) {
              if (resp.data.data[0].msg) newErr[code] = resp.data.data[0].msg;
              else if (resp.data.data[0].message)
                newErr[code] = resp.data.data[0].message;
            }
            if (!newErr[code]) newErr[code] = "Unable to upload the image";
            setErrorTracker(newErr);
            setLoadingFunction(false);
          }
        },
        error: (err) => {
          console.log(err);
          var parent = data.sequence[curIndex];
          var newErr = { ...errorTracker[parent.code] };
          newErr[code] = "Unable to process the image";
          setErrorTracker({ ...state, [parent.code]: newErr });
          setLoadingFunction(false);
          console.log("Error while compressing", err);
        },
      });
    }
  };

  const getCameraContent = () => {
    if (data) {
      var sections = data.sections;
      var photos = sections?.photos;
      return (
        <div
          className={style.camera_form_container}
          style={{ display: cameraFieldDisplay }}
        >
          <GenerateFormFromTemplate
            data={photos}
            parent={"photos"}
            state={state?.["photos"]}
            onChange={uploadInspectionPhoto}
            errors={errorTracker}
          />
        </div>
      );
    }
  };

  const handleDamageSelection = (top, bottom, x, action, val, name, code) => {
    if (!action) {
      setShowDamageMenu(action);
      return;
    }
    const height = window.outerHeight;
    var pos = "bottom";
    if (bottom + 250 > height) {
      pos = "top";
    }
    setDamagePosition(pos);
    setDamageYCoordinates({ start: top, end: bottom });
    setDamagePointerCoordinates(x);
    setShowDamageMenu(action);
    setDamageOptions(name);
    setTempValues({ val: val, name: name, code: code });
  };

  const handleDamageExtentSelection = (extent) => {
    var parent = data.sequence[curIndex];
    var newObj = { ...state[parent.code] };
    var prevVal = tempValues.name;
    newObj[tempValues.code] = { data: extent, include: true };
    setState({ ...state, [parent.code]: newObj });
    setShowDamageMenu(false);
    setTempValues(null);
    if (extent === "damaged_high") {
      var requiredPhotos = { ...requiredSupportingPhotos };
      if (requiredPhotos[parent.code]) {
        requiredPhotos[parent.code][tempValues.code] = true;
      } else {
        requiredPhotos[parent.code] = {};
        requiredPhotos[parent.code][tempValues.code] = true;
      }
      setRequiredSupportingPhotos(requiredPhotos);
    } else {
      var requiredPhotos = { ...requiredSupportingPhotos };
      if (
        requiredPhotos[parent.code] &&
        requiredPhotos[parent.code][tempValues.code]
      ) {
        requiredPhotos[parent.code][tempValues.code] = false;
      }
      setRequiredSupportingPhotos(requiredPhotos);
    }
    if (errorTracker[tempValues.code]) {
      var validator = {};
      var dt = data.sections[parent.code];
      for (var i of dt) {
        if (dt.code === tempValues.code) {
          validator = dt.validation;
        }
      }
      var newState = {};
      for (const [key, value] of Object.entries(state)) {
        newState[key] = value?.data;
      }
      var validation = handleValidations(
        extent,
        validator,
        newState,
        inspectionData
      );
      var thisError = null;
      if (!validation.success) {
        thisError = validation.message;
      }
      setErrorTracker({ ...errorTracker, [tempValues.code]: thisError });
    }
  };

  const onSupportingImageChange = (
    evt,
    code,
    setLoadingFunction,
    setErrorFunction
  ) => {
    var val = evt.target.files[0];
    if (val) {
      new Compressor(val, {
        quality: 0.4,
        convertSize: 100000,
        success: async (result) => {
          var token = await auth.currentUser.getIdToken();
          var configs = {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          };
          var formData = new FormData();
          var parent = data.sequence[curIndex];
          formData.append("image", result, result.name);
          var resp = await BackendAPI.post(
            `/inspection/upload/supporting-image?key=${code}&inspection_id=${inspectionData.inspection_id}&section=${parent.code}`,
            formData,
            configs
          );
          if (resp.status === 200) {
            var newObj = { ...supportingPhotos[parent.code] };
            var newErr = { ...errorTracker };
            newErr[code] = null;
            newObj[code] = resp.data.data.url;
            setSupportingPhotos({ ...supportingPhotos, [parent.code]: newObj });
            setErrorTracker(newErr);
            setLoadingFunction(false);
          } else {
            var newErr = { ...errorTracker };
            newErr[code] = null;
            if (resp.data && resp.data.data) {
              if (resp.data.data[0].msg) newErr[code] = resp.data.data[0].msg;
              else if (resp.data.data[0].message)
                newErr[code] = resp.data.data[0].message;
            }
            if (!newErr[code]) newErr[code] = "Unable to upload the image";
            setErrorTracker(newErr);
            setLoadingFunction(false);
            setErrorFunction(true);
          }
        },
        error: (err) => {
          setLoadingFunction(false);
          setErrorFunction(true);
          console.log("Error while compressing", err);
        },
      });
    }
  };

  const generateContent = () => {
    if (currentPageData) {
      var parent = data.sequence[curIndex];
      // if (parent.code === "photos") {
      //   if (cameraFieldDisplay !== "block") {
      //     setCameraFieldDisplay("block");
      //   }
      //   return <div />;
      // }
      // if (cameraFieldDisplay !== "none") {
      //   setCameraFieldDisplay("none");
      // }
      return (
        <div className={style.form_container}>
          <GenerateFormFromTemplate
            data={currentPageData}
            parent={parent.code}
            state={state?.[parent.code]}
            requiredFields={requiredFields[parent.code]}
            hiddenFields={hiddenFields[parent.code]}
            onChange={updateState}
            handleDamageSelection={handleDamageSelection}
            errors={errorTracker}
            completeData={state}
            inspectionData={inspectionData}
            supportingPhotos={supportingPhotos?.[parent.code]}
            onSupportingImageChange={onSupportingImageChange}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Loader />
        </div>
      );
    }
  };

  const StatusItem = ({ status = false, title = "", index = 0 }) => {
    return (
      <div
        className={style.status_item}
        onClick={() => {
          setShowStatusPage(false);
          setCurIndex(index);
        }}
      >
        <div className={style.status_icon_container}>
          {!status ? (
            <img src={ic_error} className={style.status_icon} />
          ) : status === "loading" ? (
            <Loader />
          ) : (
            <img src={ic_success} className={style.status_icon} />
          )}
        </div>
        <div className={style.status_text}>{title}</div>
        <div className={style.status_right_icon_container}>
          <img src={ic_right} className={style.status_icon} />
        </div>
      </div>
    );
  };

  const renderStatusPage = () => {
    if (cameraFieldDisplay !== "none") {
      setCameraFieldDisplay("none");
    }
    return (
      <div className={style.status_page_container}>
        {data
          ? data.sequence.map((val, index) => {
              return (
                <>
                  <StatusItem
                    title={val.name}
                    index={index}
                    status={statuses[val.code]?.status}
                  />
                  {statuses[val.code]?.message ? (
                    <p className={style.error_message_status}>
                      {statuses[val.code]?.message}
                    </p>
                  ) : null}
                  {index === data.sequence.length - 1 ? null : (
                    <HorizontalDivider />
                  )}
                </>
              );
            })
          : null}
      </div>
    );
  };

  const handleBack = () => {
    if (curIndex > 0) {
      setCurIndex(curIndex - 1);
    }
  };

  const validateTheData = (newState) => {
    var code = data.sequence[curIndex];
    var dt = data.sections[code.code];
    var newError = {};
    var validationSuccess = true;
    for (var i of dt) {
      var validation = handleValidations(
        newState[code.code]?.[i.code],
        i.validation,
        newState,
        inspectionData
      );

      if (
        validation.success &&
        i.code === "rc_number" &&
        code.code === "basic_details" &&
        newState[code.code]?.[i.code]
      ) {
        var validation2 = rcNumberValidator(newState[code.code]?.[i.code]);
        if (!validation2) {
          validation = {
            success: false,
            message: "Please provide a valid RC Number",
          };
        }
      }

      if (!validation.success) {
        validationSuccess = false;
        newError[i.code] = validation.message;
      }
    }
    setErrorTracker(newError);
    return validationSuccess;
  };

  const validateSupportingPhotos = () => {
    var code = data.sequence[curIndex];
    var supportingPhotosData = supportingPhotos[code.code];
    var supportingPhotosRequired = requiredSupportingPhotos[code.code];
    if (supportingPhotosRequired) {
      var newError = {};
      var validationSuccess = true;
      for (const [key, value] of Object.entries(supportingPhotosRequired)) {
        if (value && (!supportingPhotosData || !supportingPhotosData[key])) {
          newError[key] = "Please add a photo for this field";
          validationSuccess = false;
        }
      }
      setErrorTracker(newError);
      return validationSuccess;
    }
    return true;
  };

  const handleNext = async () => {
    var newState = {};
    for (const [key, value] of Object.entries(state)) {
      if (value) {
        var val = {};
        for (const [key1, value1] of Object.entries(value)) {
          val[key1] = value1?.data;
        }
        newState[key] = val;
      } else {
        newState[key] = value;
      }
    }
    var validations = validateTheData(newState);
    if (!validations) {
      return;
    }
    var supportingPhotosValidation = validateSupportingPhotos();
    if (!supportingPhotosValidation) {
      return;
    }
    var code = data.sequence[curIndex];
    var cur_state = { ...state };
    var temp_data = cur_state[code.code];
    var cur_data = {};
    for (const [key, value] of Object.entries(temp_data)) {
      if (value?.include) cur_data[key] = value?.data;
    }
    dispatch(
      updateFormDetails({
        code: code.code,
        data: temp_data,
        section_identifier: cur_state.section_identifier,
      })
    );
    dispatch(
      updateStatuses({
        code: code.code,
        status: { name: code.name, status: "loading" },
      })
    );
    if (formCompletedOnce) {
      setShowStatusPage(true);
    } else if (curIndex < maxIndex) {
      setCurIndex(curIndex + 1);
    } else {
      setFormCompletedOnce(true);
      setShowStatusPage(true);
    }
    // if (Object.keys(cur_data).length === 0) {
    //   dispatch(
    //     updateStatuses({
    //       code: code.code,
    //       status: { name: code.name, status: true },
    //     })
    //   );
    //   return;
    // }
    try {
      var postData = {
        section_code: code.code,
        [code.code]: cur_data,
        section_identifier: cur_state.section_identifier,
      };
      var token = await auth.currentUser.getIdToken();
      var configs = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };
      var resp = await BackendAPI.put(
        `/inspection/${inspectionData.inspection_id}?asset_type=${inspectionData.asset_type}`,
        postData,
        configs
      );
      if (resp.status === 200) {
        dispatch(
          updateStatuses({
            code: code.code,
            status: { name: code.name, status: true },
          })
        );
      } else {
        var msg;
        if (resp.data && resp.data.data) {
          if (resp.data.data[0].msg) msg = resp.data.data[0].msg;
          else if (resp.data.data[0].message) msg = resp.data.data[0].message;
        }
        if (!msg) {
          msg = "Network Error";
        }
        dispatch(
          updateStatuses({
            code: code.code,
            status: {
              name: code.name,
              status: false,
              message: msg,
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        updateStatuses({
          code: code.code,
          status: { name: code.name, status: false, message: error.message },
        })
      );
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (!pageLoading) {
      setPageLoading(true);
      var sequence = data.sequence;
      var loading = false,
        error = false;
      for (var i of sequence) {
        if (!statuses[i.code].status) {
          error = true;
        } else if (statuses[i.code].status === "loading") {
          loading = true;
        }
      }
      if (error) {
        setToastMsg("Please ensure all the fields are submitted");
        setToastType("error");
        setShowToast(true);
        setPageLoading(false);
      } else if (loading) {
        setToastMsg("Please wait while the data is being submitted");
        setToastType("info");
        setShowToast(true);
        setPageLoading(false);
      } else {
        try {
          var token = await auth.currentUser.getIdToken();
          var configs = {
            headers: {
              Authorization: `bearer ${token}`,
            },
          };
          var resp = await BackendAPI.post(
            `/inspection/${inspectionData.inspection_id}?asset_type=${inspectionData.asset_type}`,
            {},
            configs
          );
          if (resp.status === 200) {
            setPageLoading(false);
            navigation("/asset/submit", { replace: true });
          } else {
            var msg;
            if (resp.data && resp.data.data) {
              if (resp.data.data[0].msg) msg = resp.data.data[0].msg;
              else if (resp.data.data[0].message)
                msg = resp.data.data[0].message;
            }
            if (!msg) msg = "Looks like there's some issue in our end!";
            setPageError(msg);
            setPageLoading(false);
          }
        } catch (error) {
          console.log(error);
          setPageLoading(false);
          setPageError("Network issues. Please try again");
        }
      }
    }
  };

  const bottomBannerItems = () => {
    if (showStatusPage) {
      return (
        <>
          <div className="bottom-banner-next-button-container">
            <CustomButton
              buttonText={pageLoading ? <Loader /> : "Submit"}
              onClick={handleSubmit}
            />
          </div>
        </>
      );
    } else if (formCompletedOnce) {
      return (
        <>
          <div className="bottom-banner-next-button-container">
            <CustomButton buttonText={"Done"} onClick={handleNext} />
          </div>
        </>
      );
    } else {
      if (curIndex === 0) {
        return (
          <>
            <div className="bottom-banner-next-button-container">
              <CustomButton buttonText={"Next"} onClick={handleNext} />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="bottom-banner-back-button-container">
              <CustomOutlineButton
                buttonText={"Back"}
                onClick={handleBack}
                textColor={PRIMARY_1_SHADE_1}
              />
            </div>
            <div className="bottom-banner-next-button-container">
              <CustomButton buttonText={"Next"} onClick={handleNext} />
            </div>
          </>
        );
      }
    }
  };

  const handleOnClose = () => {
    if (!showStatusPage) {
      setShowStatusPage(true);
    } else {
      setOptionsMenuDetails({
        title: "Do you want to discard this inspection?",
        cancel: "No",
        success: "Yes",
        cancelColor: SUCCESS_MEDIUM,
        successColor: ERROR_DARK,
      });
      setShowOptionsMenu(true);
    }
  };

  const closeOptionsModal = () => {
    setShowOptionsMenu(false);
  };

  const onOptionsMenuSuccess = () => {
    // Discard the inspection
    dispatch(clearDetails());
    dispatch(resetAppData());
    navigation("/select-asset");
  };

  const logout = () => {
    deleteCookie("uid");
    deleteCookie("signin-status");
    deleteCookie("name");
    dispatch(clearDetails());
    dispatch(resetAppData());
    dispatch(logoutUser());
    navigation("/login");
  };

  return (
    <div className={style.inspection_container}>
      <TopBanner
        heading={data && curIndex >= 0 ? data.sequence[curIndex].name : ""}
        id={inspectionData.inspection_id ? inspectionData.inspection_id : "XX"}
        additionalText={
          inspectionData.asset_ownership
            ? inspectionData.asset_ownership
            : "Electrifi"
        }
        onClose={handleOnClose}
      />
      <LoaderModal show={showPageLoadingModal} />
      <SingleOptionModal
        show={showLogoutModal}
        title="You have been logged out. Please login again"
        buttonText="Login"
        onClick={logout}
      />
      <OptionsModal
        show={showOptionsMenu}
        closeModal={closeOptionsModal}
        title={optionsMenuDetails.title}
        cancelButtonText={optionsMenuDetails.cancel}
        successButtonText={optionsMenuDetails.success}
        cancelButtonColor={optionsMenuDetails.cancelColor}
        successButtonColor={optionsMenuDetails.successColor}
        onCancel={closeOptionsModal}
        onSuccess={onOptionsMenuSuccess}
      />
      <ToastMessage
        message={toastMsg}
        type={toastType}
        open={showToast}
        setOpen={(v) => setShowToast(v)}
      />
      <HorizontalDivider />
      {/* {getCameraContent()} */}
      {showStatusPage ? null : generateContent()}

      <DamageExtentMenu
        show={showDamageMenu}
        containerY={{
          start: damageYCoordinates?.start,
          end: damageYCoordinates?.end,
        }}
        pointerX={damagePointerCoordinates}
        handleClose={() => {
          setShowDamageMenu(!showDamageMenu);
          setTempValues(null);
        }}
        handleSelection={handleDamageExtentSelection}
        position={damagePosition}
        dataMap={damageOptions}
      />
      {showStatusPage ? renderStatusPage() : null}
      {pageError ? (
        <div className={style.error_container}>
          <p className={style.error_message}>{pageError}</p>
        </div>
      ) : null}
      <HorizontalDivider />
      <BottomBanner items={bottomBannerItems()} />
    </div>
  );
};

export default AssetInspection;
